<template>
  <van-row justify="center" class="page-wrapper">
    <van-col
      span="24"
      class="round-shadow-btn flex-display-fix container_width mt-15"
    >
      <div
        class="apply-loader itserve-info-message-new round-shadow-btn-new-new"
      >
        <span class="info-content-new">
          <slot>{{ $t("shareApp.text") }}</slot>
        </span>
      </div>
    </van-col>
    <template v-if="facebookInstalled === true">
      <van-col span="24" class="itserve-center">
        <itserve-button
          :value="$t('shareApp.button')"
          type="primary"
          @click="facebookShare"
          class="less-tall-btn mt-25"
          :icon="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="35"
            height="35"
            fill="white"
            style="margin-left: 50px"
          >
            <path
              d="M14.5 3H11c-2.21 0-4 1.79-4 4v3H5v4h2v8h4v-8h3.5l.5-4h-4V7c0-.55.45-1 1-1h3V3z"
            />
          </svg>
        </itserve-button>
      </van-col>
    </template>
    <template v-else>
      <van-col span="24" class="itserve-center">
        <itserve-button
          :value="$t('shareApp.button')"
          type="primary"
          @click="socialShare"
          class="less-tall-btn mt-25"
        ></itserve-button>
      </van-col>
    </template>
  </van-row>
</template>
<script>
import ItserveButton from "@/components/forms/ItserveButton";
import Settings from "@/plugins/api/factories/Settings";
export default {
  name: "ShareApp",
  components: { ItserveButton },
  computed: {
    account() {
      return this.$store.state.Auth.account;
    },
    facebookInstalled() {
      return this.$store.state.Auth.nativeData.facebook_installed;
    },
  },
  data() {
    return {
      paymentUrl: null,
    };
  },
  created() {
    this.nativeWaitForEvent("socialShareStatus", (params) => {
      if (params.status === true && this.$route.name === "ShareApp") {
        Settings.setShareFacebookStatus();
        Settings.setShareStatus();
        this.$store.commit("Auth/SET_USER_SHARE_STATUS", 1);
        this.$router.push({ name: "EasyPayAllServicePage" });
      }
    });
  },
  destroyed() {
    clearInterval(this.watchInterval);
  },
  methods: {
    facebookShare() {
      // let url;
      // if(this.$store.state.Auth.nativeData.platform === "ios") {
      //   url = "https://apps.apple.com/es/app/%D0%BA%D0%B8%D1%97%D0%B2-%D0%B5%D0%BD%D0%B5%D1%80%D0%B3%D0%BE-%D1%96%D0%BD%D1%84%D0%BE/id1672364825";
      // } else {
      //   url = "https://play.google.com/store/apps/details?id=kyiv.energo&hl=uk&gl=uk";
      // }

      this.nativeSendEvent("formatSocialShare", {
        text: this.$t("settings.title"),
        url: "https://billing.city/zakarpattya.energo",
        format: "shareViaFacebook",
      });
    },
    socialShare() {
      this.nativeSendEvent("socialShare", {
        text:
          this.$t("settings.title") + " " + "https://billing.city/zakarpattya.energo",
        subject: this.$t("settings.subject"),
      });

      Settings.setShareStatus();
      this.$store.commit("Auth/SET_USER_SHARE_STATUS", 1);

      setTimeout(async () => {
        await this.$router.push({ name: "EasyPayAllServicePage" });
      }, 10000);
    },
  },
};
</script>

<style scoped>
.itserve-info-message-new {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #0b0b0b;
  font-size: 18px;
  text-align: justify;
  max-width: 337px;
}
.info-content-new {
  max-width: 317px;
  display: inline-block;
  text-align: center;
  padding: 10px;
  white-space: pre-line;
}

.app-theme-dark .round-shadow-btn-new-new {
  border-radius: 15px;
  -webkit-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  padding-bottom: 15px;
  background-color: #474545 !important;
  box-shadow: none !important;
  color: #fff;
}
</style>
